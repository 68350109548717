import './App.css';
import React from 'react';
import { useState, useEffect } from 'react';
import { ethers } from "ethers";

import ABIS from './abi.json';

function App() {

  const contract = new ethers.utils.Interface(ABIS);

  const [dataInput, setDataInput] = useState({
    tokenAddress: '',
    honeypotCheck: false,
    buyAmount: '',
    pairAddress: '-',
    customPair: '',
    buyTime: '',
    slippage: '',
    mode: '-',
    maxBuy: '',
    gasLimit: '',
    gasPrice: '5'
  });

  const [walletStats, setWalletStats] = useState({
    walletAddress: '',
    bnbBalance: '',
    busdBalance: '',
    chainId: '',
    chainName: ''
  });

  const [isConnected, setIsConnected] = useState(true);
  const [isCustomPairHidden, setIsCustomPairHidden] = useState(true);
  const [isMaxBuyHidden, setIsMaxBuyHidden] = useState(true);
  const [isMissing, setIsMissing] = useState(true);
  const [isWhitelisted, setIsWhitelisted] = useState(false);
  const [logs, setLogs] = useState([]);

  const toHex = (num) => `0x${num.toString(16)}`;
  // const fromHex = (str) => parseInt(str);


  useEffect(() => {
    const items = JSON.parse(localStorage.getItem('logs'));
    if (items) {
      setLogs(items);
    }
  }, []);

  useEffect(() => {
    localStorage.setItem('logs', JSON.stringify(logs));
  }, [logs]);

  const renderLogs = (item) => {
    const d = new Date(item.time);
    const time = `${d.getDate()}.${d.getMonth()}.${d.getFullYear()} ${d.getHours()}:${d.getMinutes()}:${d.getSeconds()}`;
    const url = `https://bscscan.com/tx/${item.txHash}`

    return (
      <>
        <span>{time} </span><span className='fw-bold'>{item.method} </span><a href={url} rel="noreferrer" target="_blank">{item.txHash}</a>
      </>
    )
  }

  const handleInput = (e) => {

    if (e.target.name === 'pairAddress' && e.target.value === 'custom') {
      setIsCustomPairHidden(false);
      setDataInput({
        ...dataInput,
        pairAddress: 'custom'
      })
    } else if (e.target.name === 'mode' && e.target.value === 'maxbuy') {
      setIsMaxBuyHidden(false);
      setDataInput({
        ...dataInput,
        mode: 'maxbuy'
      })
    } else {
      if (e.target.name === 'customPair') {
        setDataInput({
          ...dataInput,
          pairAddress: e.target.value,
          [e.target.name]: e.target.value
        })
      } else if (e.target.name === 'maxbuy') {
        setDataInput({
          ...dataInput,
          maxBuy: e.target.value,
          [e.target.name]: e.target.value
        })
      } else if (e.target.name === 'honeypotCheck') {
        setDataInput({
          ...dataInput,
          honeypotCheck: e.target.checked
        })
      } else {
        if (e.target.name === 'slippage' && e.target.value > 100) {
          e.target.value = 100;
        }
        setIsCustomPairHidden(true);
        setIsMaxBuyHidden(true);
        setDataInput({
          ...dataInput,
          [e.target.name]: e.target.value
        })
      }
    }
  }

  const onClickConnect = async () => {
    if (!window.ethereum) {
      alert("please install MetaMask")
      return
    }
    const provider = new ethers.providers.Web3Provider(window.ethereum);
    const requestAccounts = await provider.send("eth_requestAccounts", []);
    if (requestAccounts.length > 0) {
      const balance = await provider.getBalance(requestAccounts[0])
      const network = await provider.getNetwork();
      setWalletStats({
        ...walletStats,
        walletAddress: requestAccounts[0],
        chainId: network.chainId,
        chainName: network.name,
        bnbBalance: ethers.utils.formatEther(balance)
      })
      setIsConnected(false);

      const contractS = new ethers.Contract("0x457ab9F2de7dee5b69842953aD65524b4F79b33d",
        ABIS
        , provider)

      const whitelist = await contractS.whitelist(requestAccounts[0]);
      setIsWhitelisted(whitelist);
    }
  }

  const onClickSwitch = async () => {
    await window.ethereum.request({
      method: "wallet_requestPermissions",
      params: [
        {
          eth_accounts: {}
        }
      ]
    });

    window.location.reload();
  }

  const onClickApprove = async () => {
    let ABI = ["function approve(address to, uint amount)"];
    let iface = new ethers.utils.Interface(ABI);
    const params = iface.encodeFunctionData("approve", ["0x457ab9F2de7dee5b69842953aD65524b4F79b33d", "0xffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffff"]);
    let transactionParameters = {
      to: "0xe9e7CEA3DedcA5984780Bafc599bD69ADd087D56",
      from: walletStats.walletAddress,
      value: toHex(parseInt(0)),
      data: params
    };

    const txHash = await window.ethereum.request({
      method: 'eth_sendTransaction',
      params: [transactionParameters],
    });

    const now = Date.now();
    setLogs([...logs, { time: now, method: "Approve BUSD", txHash }]);
  }

  const onClickDisconnect = () => {
    setWalletStats({
      ...walletStats,
      walletAddress: '',
      bnbBalance: '',
    })

    setDataInput({
      tokenAddress: '',
      honeypotCheck: false,
      buyAmount: '',
      pairAddress: '-',
      customPair: '',
      buyTime: '',
      slippage: '',
      mode: '-',
      maxBuy: '',
      gasLimit: '',
      gasPrice: '5'
    })
    setIsConnected(true);
  }

  const onClickClearLogs = () => {
    setLogs([]);
    localStorage.clear();
  }

  const onClickSnipe = async () => {

    const mode = dataInput.mode;
    const maxbuy = dataInput.maxBuy;
    const tokenAddress = dataInput.tokenAddress;
    const buyAmount = parseFloat(dataInput.buyAmount);
    const pairAddress = dataInput.pairAddress;
    const customPair = dataInput.customPair;
    const buyTime = dataInput.buyTime;
    const slippage = dataInput.slippage;
    const gasLimit = dataInput.gasLimit;
    const gasPrice = dataInput.gasPrice;

    if (mode === '-' || pairAddress === '-' || !tokenAddress || !buyAmount || !buyTime || !slippage) {
      alert('Please fill all the fields');
      setIsMissing(false);
      return;
    } else {
      setIsMissing(true);
    }

    let method;
    let params;
    let value = toHex(parseInt(0));
    let honeypotCheck;

    if (dataInput.honeypotCheck) {
      honeypotCheck = 1;
    } else {
      honeypotCheck = 0;
    }

    if (mode === 'standard') {
      let pair;

      if (pairAddress === 'custom') {
        pair = customPair;
      } else {
        pair = pairAddress;
      }

      method = 'swapExactETHForTokens';
      params = contract.encodeFunctionData(method, [parseInt(buyTime), [walletStats.walletAddress], pair, tokenAddress, honeypotCheck, parseInt(slippage)]);
      value = buyAmount * buyTime + 0.000001;
      value = ethers.utils.parseUnits(String(value), "ether").toHexString();
    } else if (mode === 'maxbuy') {
      let pair;
      if (pairAddress === 'custom') {
        pair = customPair;
      } else {
        pair = pairAddress;
      }

      method = 'swapETHForExactTokens';
      params = contract.encodeFunctionData(method, [parseInt(buyTime), [walletStats.walletAddress], String(maxbuy), pair, tokenAddress, honeypotCheck, parseInt(slippage)]);
      value = buyAmount * buyTime + 0.000001;
      value = ethers.utils.parseUnits(String(value), "ether").toHexString();
    }

    let transactionParameters = {
      to: "0x457ab9F2de7dee5b69842953aD65524b4F79b33d",
      from: walletStats.walletAddress,
      value: value,
      data: params
    };

    if (gasLimit !== '') {
      transactionParameters.gas = toHex(gasLimit);
    }

    if (gasPrice !== '') {
      transactionParameters.gasPrice = ethers.utils.parseUnits(String(gasPrice), "gwei").toHexString();
    }
    const txHash = await window.ethereum.request({
      method: 'eth_sendTransaction',
      params: [transactionParameters],
    });

    const now = Date.now();
    setLogs([...logs, { time: now, method: method, txHash }]);

  }

  return (
    <>
      <div className='container'>
        <div className='row'>

          <h1 className='text-center fw-bold'>Meki Swap</h1>
          <hr />

          <div className="networkAlert">

          </div>

          <div className='connectArea mb-3'>
            {isConnected ?
              <button className='connectBtn px-3 py-3 btn btn-secondary' onClick={onClickConnect}>Connect</button>
              :
              <>
                <button className='disconnectBtn px-3 py-3 btn btn-secondary bg-danger fw-bold' onClick={onClickDisconnect}>Disconnect</button>
                <button className='switchBtn px-3 py-3 ms-3 btn btn-secondary bg-primary fw-bold' onClick={onClickSwitch}>Switch</button>
                <button className='approveBtn px-3 py-3 ms-3 btn btn-secondary bg-success fw-bold' onClick={onClickApprove}>Approve</button>
              </>
            }
          </div>

          <div className='connectionStatus' hidden={isConnected}>
            <div className='accountData text-center fw-bold mt-3'>
              <span>
                <p>Your Wallet Stats</p>
              </span>
              <span>
                <p>{walletStats.walletAddress}</p>
              </span>
              <span>
                <p>{walletStats.bnbBalance} BNB</p>
              </span>
              {isWhitelisted ?
                <>
                  <span>
                    <p className='text-danger fw-bold'>Use With Your Own Risk!</p>
                  </span>
                </> : <>
                  <p className='text-danger fw-bold'>You're Not Whitelisted!</p>
                </>
              }

            </div>

            {isWhitelisted &&
              <div className='swap mt-5'>

                <div className="mb-3">
                  <label className="fw-bold">Token Address</label>
                  <input type="text" className="form-control" id="tokenAddress" name='tokenAddress' placeholder="0xe9e7CEA3DedcA5984780Bafc599bD69ADd087D56" value={dataInput.tokenAddress} onChange={handleInput} required />
                </div>

                <div className="mb-3">
                  <label className="fw-bold">Buying Amount</label>
                  <input type="number" className="form-control" id="buyAmount" name='buyAmount' placeholder="0" value={dataInput.buyAmount} onChange={handleInput} required />
                </div>

                <div className="mb-3">
                  <label className="fw-bold">Pair Address</label>
                  <select className="form-select" name='pairAddress' value={dataInput.pairAddress} onChange={handleInput} required>
                    <option>-</option>
                    <option value="0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c">BNB</option>
                    <option value="0xe9e7CEA3DedcA5984780Bafc599bD69ADd087D56">BUSD</option>
                    <option value="custom">Custom Pair</option>
                  </select>
                </div>

                <div className="mb-3" hidden={isCustomPairHidden}>
                  <label className="fw-bold">Custom Pair Address</label>
                  <input type="text" className="form-control" id="customPair" name='customPair' placeholder="0x" value={dataInput.customPair} onChange={handleInput} />
                </div>

                <div className="mb-3">
                  <label className="fw-bold">Buying Time(s)</label>
                  <input type="number" className="form-control" id="buyTime" name='buyTime' placeholder="0" value={dataInput.buyTime} onChange={handleInput} required />
                </div>

                <div className="mb-3">
                  <label className="fw-bold">Slippage(%)</label>
                  <input type="number" className="form-control" id="slippage" name='slippage' placeholder="100" value={dataInput.slippage} onChange={handleInput} required />
                </div>

                <div className="mb-3">
                  <label className="fw-bold">Mode</label>
                  <select className="form-select" name='mode' value={dataInput.mode} onChange={handleInput}>
                    <option>-</option>
                    <option value="standard">Standard Swap</option>
                    <option value="maxbuy">MaxBuy Swap</option>
                  </select>
                </div>

                <div className="mb-3" hidden={isMaxBuyHidden}>
                  <label className="fw-bold">MaxBuy Amount</label>
                  <input type="text" className="form-control" id="maxbuy" name='maxbuy' placeholder="100000000000" value={dataInput.maxBuy} onChange={handleInput} />
                </div>

                <div className="mb-3">
                  <label className="fw-bold">Gas Limit</label>
                  <input type="number" className="form-control" id="gasLimit" name="gasLimit" placeholder="300000" value={dataInput.gasLimit} onChange={handleInput} />
                </div>

                <div className="mb-3">
                  <label className="fw-bold">Gas Price</label>
                  <input type="number" className="form-control" id="gasPrice" name='gasPrice' placeholder="5" value={dataInput.gasPrice} onChange={handleInput} />
                </div>

                <div className="mb-3">
                  <label className="fw-bold">Honeypot Check</label>
                  <input type="checkbox" className="form-check-input ms-2" id="honeypotCheck" name='honeypotCheck' checked={dataInput.honeypotCheck} onChange={handleInput} />
                </div>

                <div className='mb-3'>
                  <button type="submit" className='snipe px-3 py-3 btn btn-secondary fw-bold bg-primary' onClick={onClickSnipe}>Snipe Now</button>
                  <button className='clearLogs px-3 py-3 ms-3 btn btn-secondary fw-bold bg-danger' onClick={onClickClearLogs}>Clear Logs</button>
                </div>

                <div className='mb-3' hidden={isMissing}>
                  <span>
                    <p className='fw-bold text-danger'>Something is Missing in Form!</p>
                  </span>
                </div>

                {logs && logs.length > 0 &&
                  <div className='logs mb-3'>
                    <h5 className='fw-bold'>Logs</h5>
                    <div className='logArea'>
                      <ul>
                        {
                          logs.map((log, index) => {
                            return (
                              <li key={index}>{renderLogs(log)}</li>
                            )
                          })
                        }
                      </ul>
                    </div>
                  </div>
                }
              </div>
            }


          </div>
        </div>
      </div>

    </>
  );
}

export default App;
